import axios from "axios";
import qs from "qs";
var baseURL = 'https://www.paig.com.cn/'
// var baseURL = 'http://eventplus.viewcenter.cn/'
if (process.env.NODE_ENV === "development") {
	// 开发环境
	baseURL = process.env.VUE_APP_BASE_API
}
const instance = axios.create({
	baseURL: baseURL, // 代理
});

//默认请求配置
const defaultOpt = {
	login: true,
	//默认接口需要登陆
	//默认传递form表单
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
	//默认前置方法，使用form表单格式传值
	transformRequest: [
		function(data) {
			return qs.stringify(data);
		},
	],
};

/**
 *
 */
function baseRequest(options) {
	const headers = options.headers || {};
	options.headers = headers;

	return instance(options).then((res) => {
		const data = res.data || {};
		//网络请求失败
		if (res.status !== 200) {
			//请求失败
			return Promise.reject({
				msg: "请求失败",
				res,
				data,
			});
		}

		return Promise.resolve(data.data);


	});
}

const request = ["post", "put", "patch"].reduce((request, method) => {
	/**
	 *
	 * @param url string 接口地址
	 * @param data object get参数
	 * @param options object axios 配置项
	 * @returns AxiosPromise
	 */
	request[method] = (url, data = {}, paging = {}, options = {}) => {
		return baseRequest(
			Object.assign({
					url,
					data: {
						parameter: {
							...data,
							lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 6
						},
						paging
					},
					method,
				},
				defaultOpt,
				options
			)
		);
	};
	return request;
}, {});

["get", "delete", "head"].forEach((method) => {
	/**
	 *
	 * @param url string 接口地址
	 * @param params object get参数
	 * @param options object axios 配置项
	 * @returns AxiosPromise
	 */
	request[method] = (url, params = {}, options = {}) => {
		return baseRequest(
			Object.assign({
					url,
					params,
					method,
				},
				defaultOpt,
				options
			)
		);
	};
});

export default request;