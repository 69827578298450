<template>
	<div class="bottom">
		<!-- <div class="bot-title">{{lang==6?'宝爱捷（中国）品牌价值':'PAIG (China) Group Value'}}</div>
		<div class="mode6 wow fadeInUp">
			<div class="mode6-item" v-for="(item,index) in iconList" :key="index" @click="goBrandValue">
				<img :src="`${require('../assets/index/mode6-icon'+item.id+'.png')}`" class="icon" />
				<img :src="`${require('../assets/index/mode6-icon'+item.id+'-red.png')}`" class="redIcon" />
				<p class="big">{{lang==6?item.name:item.nameEN}}</p>
			</div>
		</div> -->
		<div class="flexBox">
			<div class="flexBox-item">
				<!-- <div class="title">{{lang==6?'集团会员':'Group membership'}}</div>
				<div class="flexBox-item-text">
					<p>{{lang==6?'会员级别':'Member Level'}}</p>
					<p>{{lang==6?'会员权益':'Member Benefits'}}</p>
					<p>{{lang==6?'会员活动':'Member activities'}}</p>
					<p>{{lang==6?'申请入会':'Apply for Membership '}}</p>
				</div> -->
			</div>
			<div class="flexBox-item flexBox-item1">
				<!-- <div class="title">{{lang==6?'联系我们':'Contact us'}}</div>
				<div class="flexBox-item-text flexBox-item-text2">
					<p>{{lang==6?'在线联系':'Online Contact'}}</p>
					<p>{{lang==6?'企业微信':'WeCom'}}</p>
					<p>{{lang==6?'小程序':'Mini Program'}}</p>
					<p>{{lang==6?'抖音':'Tik Tok'}}</p>
					<p>{{lang==6?'小红书':'Red'}}</p>
				</div> -->
			</div>
			<!-- <div class="flexBox-item">
				<div class="inputBox">
					<el-select v-model="search" filterable :filter-method="filterMethod" placeholder="">
						<el-option v-for="(item,index) in keyList" :key="index" :label="lang==6?item.cn:item.en"
							:value="lang==6?item.cn:item.en">
						</el-option>
					</el-select>
					<div class="btn" @click="$router.push('/search?keyword='+ search)">{{lang==6?'搜索':'SEARCH'}}</div>
				</div>
			</div> -->
		</div>
		<div class="line">
			<span @click="$router.push('/legalNotice')">{{lang==6?'法律声明':'Legal Notices'}}</span>
			<span @click="$router.push('/privacy')">{{lang==6?'个人信息保护政策':'PERSONAL DATA PROTECTION POLICY'}} </span>
			<span @click="$router.push('/cookie')">{{lang==6?'使用COOKIES':'Application of Cookies'}}</span>
			<a target="_blank"
				href="https://beian.miit.gov.cn/#/Integrated/index">{{lang==6?'浙ICP备15016564号':'Zhejiang IC No. 15016564'}}</a>
			<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802007818"><img
					src="../assets/bottom/icon9.png" />
				{{lang==6?'浙公网安备 33010802007818号':'Zhejiang Public Network Security No. 33010802007818'}}</a>
		</div>
		<div class="tip">
			{{lang==6?'© 2022 宝爱捷（中国）汽车投资有限公司':'©2022 PAIG  (China) Automobile Investment Co., Ltd.'}}
		</div>
	</div>
</template>

<script>
	import mixinLang from './mixin.js'
	export default {
		name: 'bottom',
		mixins: [mixinLang],
		data() {
			return {
				keyList: [{
						cn: '奥迪',
						en: 'Audi'
					},
					{
						cn: '宾利',
						en: 'Bentley'
					},
					{
						cn: '兰博基尼',
						en: 'Lamborghini'
					},
					{
						cn: '保时捷',
						en: 'Porsche'
					},
					{
						cn: '宝马',
						en: 'BMW'
					},
					{
						cn: 'MINI',
						en: 'MINI'
					},
					{
						cn: '集团介绍',
						en: 'Group Intro',
					},
					{
						cn: '集团新闻',
						en: 'Group News'
					},
					{
						cn: '集团历程',
						en: 'Group Milestone'
					},
					{
						cn: 'Women@PIAG',
						en: 'Women@PIAG'
					},
					{
						cn: '合规与廉正',
						en: 'Compliance & Integrity'
					},
					{
						cn: '培训学院',
						en: 'Training Academy'
					}
				],
				search: '',
				iconList: [{
						id: 1,
						name: '客户至上',
						nameEN: 'Customer Orientation'
					},
					{
						id: 5,
						name: '尊重',
						nameEN: 'Respect'
					},
					{
						id: 3,
						name: '责任和信任',
						nameEN: 'Responsibility & Trust'
					},
					{
						id: 4,
						name: '拥抱改变 不断成长',
						nameEN: 'Enjoy Change & Growth'
					},
					{
						id: 2,
						name: '团队精神',
						nameEN: 'Team spirit'
					}
				]
			}
		},
		methods: {
			goBrandValue() {
				if (this.$route.name == 'aboutUsBrandValue') {
					document.querySelector('#content').scrollIntoView({
						behavior: "smooth"
					})
				} else {
					this.$router.push('/aboutUs/brandValue?id=content')
				}
			},
			filterMethod(e) {
				this.search = e
			},
			getInfo() {}
		}
	}
</script>

<style lang="scss" scoped>
	.bottom {
		width: 100%;
		// height: 480px;
		background-color: #202938;
		color: #FFF;
	}

	.bot-title {
		padding: 54px 128px 0;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 30px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.flexBox {
		display: flex;
		font-size: 18px;
		padding: 94px 220px 0;
		justify-content: space-between;

		.flexBox-item {
			width: 500px;

			.flexBox-item-text {
				display: flex;
				flex-wrap: wrap;

				p {
					width: 50%;
					white-space: nowrap;
				}

			}

			.flexBox-item-text2 {
				p {
					width: 33%;
				}
			}

			.title {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 25px;
				text-transform: uppercase;
			}

			p {
				line-height: 40px;
			}

			img {
				width: 37px;
				margin-right: 47px;
				margin-bottom: 10px;
			}

			img:nth-child(4n) {
				margin-right: 0;
			}

			.inputBox {
				width: 396px;
				display: flex;
				position: relative;
				height: 40px;
				background-color: #FFF;
				border-radius: 5px;
				align-items: center;
				padding: 0 2px;
				// justify-content: space-between;

				input {
					border: none;
					outline: none;
					width: 100%;
				}

				/deep/.el-input__inner {
					height: 40px !important;
					font-size: 20px;
					line-height: 40px;
					border: none;
					outline: none;
				}

				/deep/.el-select {
					border: none;
					outline: none;
					height: 40px;
					line-height: 40px;
					width: 100%;
					font-family: SourceHanSansCN-Bold, sans-serif;
				}

				/deep/.el-select .el-input.is-focus .el-input__inner,
				/deep/ .el-select .el-input__inner:focus {
					border: none;
				}

				/deep/ .el-input__suffix-inner {
					display: none;
				}

				.btn {
					position: absolute;
					right: 2px;
					top: 50%;
					transform: translateY(-50%);
					color: #FFF;
					// width: 80px;
					padding: 0 10px;
					height: 32px;
					border-radius: 4px;
					background: #C09E70;
					box-shadow: 0px 12px 20px -5px rgba(23, 23, 126, 0.1);
					text-align: center;
					line-height: 32px;
					cursor: pointer;
				}
			}

			&:nth-child(3) {
				width: 396px;
			}
		}

		.flexBox-item1 {
			width: 490px;
		}
	}

	.line {
		font-size: 14px;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 109px 0 20px;
		text-transform: uppercase;
		flex-wrap: wrap;

		>span,
		a {
			cursor: pointer;
			display: inline-block;
			margin: 0 20px;
			color: #FFF;
			text-decoration: none;
			white-space: nowrap;
		}

		img {
			vertical-align: middle;
			width: 18px;
			height: 18px;
		}
	}

	.tip {
		font-size: 14px;
		text-align: center;
		border-top: #999999 solid 1px;
		line-height: 60px;
	}

	.mode6 {
		width: 100%;
		height: 353px;
		display: flex;
		justify-content: space-between;
		padding: 0 128px;
		align-items: center;

		.mode6-item {
			width: 320px;
			font-size: 10PX;
			/*no*/
			color: #787878;
			text-align: center;
			line-height: 30px;
			font-family: SourceHanSansCN-Medium, sans-serif;

			&:hover .icon {
				display: none;
			}

			&:hover .redIcon {
				display: inline-block;
			}

			.big {
				font-size: 24px;
				margin: 20px 0;
				color: #FFF;
				font-weight: 500;
			}

			.icon {
				width: 90px;
				height: 90px;
				display: inline-block;
			}

			.redIcon {
				width: 90px;
				height: 90px;
				display: none;
			}
		}
	}
</style>