import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 6
	},
	getters: {},
	actions: {},
	mutations: {
		changeLang(state){
			state.lang = state.lang == 6 ? 1 : 6
			localStorage.setItem('lang',state.lang)
		}
	}
})

export default store
