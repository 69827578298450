import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js';
import api from './api/api.js'
import ElementUI from 'element-ui';
import 'lib-flexible/flexible'; //在入口文件引入然后运行
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'
import {
	WOW
} from 'wowjs'
import animated from 'wowjs/css/libs/animate.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import './styles/viewer.scss'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Viewer.setDefaults({
	// 需要配置的属性 注意属性并没有引号
	title: false,
	toolbar: true,
	navbar: false,
  zoomable: false,
  rotatable: false,
  scalable: false,
  transition: false,
  fullscreen: false,
  keyboard: false,
  play: false,
  reset: false,
	// url: 'data-source'
})
Vue.use(MetaInfo)
Vue.use(ElementUI);
//这里需要将样式用起来，
Vue.use(animated)
//创建全局实例
Vue.prototype.$wow = new WOW({
	boxClass: 'wow', // 需要执行动画的元素的 class
	animateClass: 'animated', //animation.css 动画的 class
	offset: 100, // 距离可视区域多少开始执行动画
	mobile: true, // 是否在移动设备上执行动画
	// live: true, // 异步加载的内容是否有效
	live: false
})
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')
