// 关于我们
export default [{
	path: '/aboutUs/AFCC',
	name: 'aboutUs',
	component: () => import('@/view/aboutUs/AFCC/index'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/aboutUs/AFCC/ETTD',
	name: 'ETTD',
	component: () => import('@/view/aboutUs/AFCC/ETTD'),
	meta: {
		keepAlive: false
	}
}, {
	path: '/aboutUs/brandValue',
	name: 'aboutUsBrandValue',
	component: () => import('@/view/aboutUs/brandValue'),
	meta: {
		keepAlive: false
	}
}, {
	path: '/aboutUs/cic',
	name: 'aboutUsCic',
	component: () => import('@/view/aboutUs/cic'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/aboutUs/client',
	name: 'aboutUsClient',
	component: () => import('@/view/aboutUs/client'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/aboutUs/cooperation',
	name: 'aboutUsCooperation',
	component: () => import('@/view/aboutUs/cooperation'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/aboutUs/joinUs',
	name: 'aboutUsJoinUs',
	component: () => import('@/view/aboutUs/joinUs'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/paigWomen',
	name: 'paigWomen',
	component: () => import('@/view/aboutUs/paigWomen'),
	meta: {
		keepAlive: true
	}
}];
