<template>
	<div class="top" :class="topTransparent ? 'transparent' : 'topBg' ">
		<div class="nav">
			<img :style="logoCursor?'':'cursor:default'" src="../assets/top/logo.png" mode="widthFit" class="logo"
				@click="$router.replace('/')" />
			<div class="navRight">
				<p @click="chagneLanguage"><span :style="lang==6?'color:#97233F':''">中</span> | <span
						:style="lang!=6?'color:#97233F':''">EN</span></p>
				<!-- <span @click="chagneLanguage" v-if="EN">中文&nbsp;&nbsp; | &nbsp;&nbsp;EN</span>
				<span v-else>{{lang==6?'中文':'EN'}}</span> -->
				<div class="open"></div>
				<img src="../assets/top/open.png" class="icon openIcon" mode="widthFit" v-if="icon==1" />
				<img src="../assets/top/close.png" class="icon close" mode="widthFit" v-if="icon!=1" />
				<div class="dropdown" @mouseover="icon=2" @mouseout="icon=1">
					<div class="dropdown-item">
						<div class="dropdown-item-line" :class="dropdown==1? 'dropdown-item-line-current' : ''"
							@click="dropdown=1">
							<span>
								< </span> <span class="menuName">{{lang==6?'集团故事':'Group Story'}}</span>
						</div>
						<div class="dropdown-item-child">
							<div class="dropdown-item-line" @click="$router.replace('/brandStory/introduce')">
								<span class="menuName">{{lang==6?'集团介绍':'Profile'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.replace('/news')">
								<span class="menuName">{{lang==6?'集团新闻':'News'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.replace('/history')">
								<span class="menuName">{{lang==6?'发展历程':'Milestones'}}</span>
							</div>
						</div>
					</div>
					<div class="dropdown-item">
						<div class="dropdown-item-line" :class="dropdown==1? 'dropdown-item-line-current' : ''"
							@click="dropdown=1">
							<span>
								< </span> <span class="menuName">{{lang==6?'品牌':'Brands'}}</span>
						</div>
						<div class="dropdown-item-child">

							<div v-for="(item,index) in brandList" :key="index">
								<!-- 非保时捷 -->
								<div class="dropdown-item-line" @click="$router.push(`/brand/${item.id}`)"
									v-if="item.id!=5 && item.id!=20">
									<span></span><span class="menuName">{{item.title}}</span>
								</div>
								<!-- 保时捷有子菜单 -->
								<div class="dropdown-item" v-else>
									<div class="dropdown-item-line">
										<span>
											< </span> <span class="menuName">{{item.title}}</span>
									</div>
									<div class="dropdown-item-child">
										<div class="dropdown-item-line" @click="$router.push(`/brand/${item.id}`)">
											<span class="menuName">{{item.title}}</span>
										</div>
										<div class="dropdown-item-line" @click="$router.push('/porsche')">
											<span class="menuName">{{lang==6?'保时捷控股车队':'Team Porsche Holding'}}</span>
										</div>
									</div>
								</div>
							</div>
							<!-- @click="$router.push('/usedCarCenter')" -->
							<!-- <div class="dropdown-item-line">
								<span></span><span class="menuName">{{lang==6?'趣选二手车':'Q-Car'}}</span>
							</div> -->
						</div>
					</div>
					<!-- @click="$router.push('/activityGroup')" -->
					<!-- <div class="dropdown-item">
						<div class="dropdown-item-line">
							<span></span><span class="menuName">{{lang==6?'客户活动':'Customer Activities'}}</span>
						</div>
					</div> -->
					<div class="dropdown-item">
						<div class="dropdown-item-line" :class="dropdown==1? 'dropdown-item-line-current' : ''"
							@click="dropdown=1">
							<span>
								< </span> <span class="menuName">{{lang==6?'企业社会责任':'CSR'}}</span>
						</div>
						<div class="dropdown-item-child">
							<div class="dropdown-item-line" @click="$router.replace('/sustainability')">
								<span class="menuName">{{lang==6?'可持续发展':'Sustainability'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.replace('/paigWomen')">
								<span class="menuName">{{lang==6?'多元化与包容性':'Diversity & Inclusion'}}</span>
							</div>
							<!-- <div class="dropdown-item-line" @click="$router.replace('/Environment')">
								{{lang==6?'环境保护':'Environment'}}
							</div> -->
						</div>
					</div>
					<div class="dropdown-item">
						<div class="dropdown-item-line" :class="dropdown==4? 'dropdown-item-line-current' : ''"
							@click="dropdown=4">
							<span>
								< </span><span class="menuName">{{lang==6?'关于我们':'About Us'}}</span>
						</div>
						<div class="dropdown-item-child" v-show="dropdown == 4">
							<div class="dropdown-item-line" @click="$router.push('/aboutUs/brandValue')">
								<span class="menuName">{{lang==6?'核心价值':'Core Values'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.replace('/compliance')">
								<span class="menuName">{{lang==6?'合规与廉正':'Compliance & Integrity'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.push('/aboutUs/AFCC')">
								<span class="menuName">{{lang==6?'人才发展与学习中心':'TLC'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.push('/aboutUs/cic')">
								<span class="menuName">{{lang==6?'客户互动服务中心':'CIC'}}</span>
							</div>
							<div class="dropdown-item-line" @click="$router.push('/aboutUs/client')">
								<span class="menuName">{{lang==6?'集团大客户':'Group Fleet'}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from './mixin.js'
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	export default {
		name: 'top',
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		props: {
			topTransparent: false
		},
		watch: {
			'$route'(to, from) {
				if (to.name == 'brand' || to.name == 'brandStoryNewsDetails') {
					this.EN = false
				} else {
					this.EN = true
				}
				if (to.fullPath == '/') {
					this.logoCursor = false
				} else {
					this.logoCursor = true
				}
			}
		},
		data() {
			return {
				logoCursor: true,
				icon: 1,
				dropdown: '', //当前点击的下拉列表
				dropdownChild: '',
				brandList: [],
				EN: true
			}
		},
		methods: {
			toLink(url) {
				window.open(url)
			},
			chagneLanguage() {
				this.$store.commit('changeLang')
			},
			getInfo() {
				// 品牌列表
				this.$api.getBrandList({
					cate_no: 'b4766587',
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.brandList = res.list
				})
			}
		}

	}
</script>

<style scoped lang="scss">
	.top {
		position: absolute;
		top: 0;
		width: 100%;
		font-family: SourceHanSansCN-Light, sans-serif;
	}

	.logo {
		z-index: 999;
		cursor: pointer;

	}

	.transparent {
		padding-top: 30px;
	}

	.topBg {
		width: 100vw;
		height: 87.95px;
		left: 50%;
		transform: translateX(-50%);
		// top: 40px;
		// border-radius: 16px;
		opacity: 0.8;
		background: linear-gradient(90deg, #202938 0%, #202938 0%, #202938 100%, #202938 100%);
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
		z-index: 999;

		.nav {
			padding: 0;
			padding-right: 40px;
		}
	}

	.nav {
		width: 100%;
		color: #FFF;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 80px;
		height: 90px;
		align-items: center;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
	}

	.navRight {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 999;

		>p {
			cursor: pointer;

			span {
				display: inline-block;
			}

			span:nth-child(1) {
				margin-right: 10px;
			}

			span:nth-child(2) {
				margin-left: 10px;
			}
		}

		.open {
			position: absolute;
			right: 0;
			height: 100px;
			width: 70px;
		}

		.dropdown {
			position: absolute;
			min-width: 300px;
			background: rgba(8, 24, 41, 0.85);
			top: 35px;
			right: 0px;
			text-align: center;
			line-height: 55px;
			font-size: 20px;
			border: 1px solid #172E46;
			display: none;

			&:hover {
				display: block;
			}

			.menuName {
				width: 80%;
				text-align: left;
			}

			.dropdown-item {
				position: relative;
			}

			.dropdown-item-line {
				background: url(../assets/top/border.png) no-repeat bottom/100% 1px;
				padding: 0 11px;
				cursor: pointer;
				white-space: nowrap;
				display: flex;
				justify-content: space-between;

				// span:nth-child(1) {
				// 	float: left;
				// }

			}

			.dropdown-item-child {
				position: absolute;
				right: 100%;
				// width: 240px;
				min-width: 300px;
				top: -1px;
				background: rgba(8, 24, 41, 0.85);
				border: 1px solid #172E46;
				display: none;

				.dropdown-item-line {
					&:hover {
						background: linear-gradient(270deg, rgba(151, 35, 63, 0.00) 0%, rgba(151, 35, 63, 0.00) 0%, rgba(151, 35, 63, 0.70) 50%, rgba(151, 35, 63, 0.00) 100%, rgba(151, 35, 63, 0.00) 100%);
					}
				}

			}

			.dropdown-item:hover {
				background: linear-gradient(270deg, rgba(151, 35, 63, 0.00) 0%, rgba(151, 35, 63, 0.00) 0%, rgba(151, 35, 63, 0.70) 50%, rgba(151, 35, 63, 0.00) 100%, rgba(151, 35, 63, 0.00) 100%);
				background-size: 100% 100%;
				background-position: top;

				>.dropdown-item-child {
					display: block !important;
				}

			}
		}
	}

	.icon {
		width: 29px;
		margin-left: 40px;
	}

	.open:hover~.dropdown {
		display: block !important;
	}

	.logo {
		width: 307px;
	}
</style>