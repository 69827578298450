import request from './index.js'
export default{
	// 首页
	getConfigure(data, paging={}) {
	    return request.post("/api/page/getConfigure",data, paging);
	},
	// 品牌故事
	getArticleList(data, paging={}) {
	    return request.post("/api/article/getArticleList",data, paging); //新闻列表
	},
	getArticleDetails(data, paging={}) {
		return request.post("/api/article/getArticleDetails",data, paging);//新闻详情
	},
	getIntroduction(data, paging={}) {
		return request.post("/api/group/getIntroduction",data, paging);//集团介绍
	},
	// 公共模块
	getBannerList(data, paging={}) {
		return request.post("/api/banner/getBannerList",data, paging); //banner列表
	},
	getAtlasList(data, paging={}) {
		return request.post("/api/atlas/getAtlasList",data, paging);//获取图集列表
	},
	getActivityList(data, paging={}) {
		return request.post("/api/Activity/getActivityList",data, paging);//获取信息发布列表
	},
	getActivityDetails(data, paging={}) {
		return request.post("/api/Activity/getActivityDetails",data, paging);//获取信息详情
	},
	getBrandList(data, paging={}) {
		return request.post("/api/brand/getBrandList",data, paging);//获取品牌列表
	},
	// 保时捷车队
	teamGetIntroduction(data, paging={}) {
		return request.post("/api/team/getIntroduction",data, paging);//基本信息
	},
	getMemberList(data, paging={}) {
		return request.post("/api/team/getMemberList",data, paging);//获取保时捷车队队员列表
	},
	getScheduleList(data, paging={}) {
		return request.post("/api/team/getScheduleList",data, paging);//获取比赛赛程
	},
	// 品牌详情
	getBrandDetails(data, paging={}) {
		return request.post("/api/brand/getBrandDetails",data, paging);//获取品牌详情
	},
	// 获取省份
	getProvinceList(data, paging={}) {
		return request.post("/api/region/getProvinceList",data, paging);
	},
	// 获取市区
	getCityList(data, paging={}) {
		return request.post("/api/region/getCityList",data, paging);
	},
	// 获取经销商
	getDealerList(data, paging={}) {
		return request.post("/api/dealer/getDealerList",data, paging);
	},
	// 获取新闻年列表
	getYearList(data, paging={}) {
		return request.post("/api/article/getYearList",data, paging);
	},
	// 获取车型列表
	getVehicleTypeList(data, paging={}) {
		return request.post("/api/purchase/getVehicleTypeList",data, paging);
	},
	// 提交购车申请
	savePurchase(data, paging={}) {
		return request.post("/api/purchase/savePurchase",data, paging);
	},
	// 经销商详情
	getDealerDetails(data, paging={}) {
		return request.post("/api/dealer/getDealerDetails",data, paging);
	},
	// 全站搜索
	getInformationList(data, paging={}) {
		return request.post("/api/search/getInformationList",data, paging);
	},
	// 发展历程
	getCourseList(data, paging={}) {
		return request.post("/api/course/getCourseList",data, paging);
	},
	// 往期活动
	getPreviousList(data, paging={}) {
		return request.post("/api/previous/getPreviousList",data, paging);
	},
	// 获取最近的经销商
	getLatelyDealerDetails(data, paging={}) {
		return request.post("/api/dealer/getLatelyDealerDetails",data, paging);
	},
	
} 