import Vue from "vue";
import Router from "vue-router";
import brandStory from "./module/brandStory.js";
import activity from "./module/activity.js";
import aboutUs from "./module/aboutUs.js";
Vue.use(Router);

const router = new Router({
	// mode: "history",
	routes: [{
			path: '/',
			name: '首页',
			component: () => import('@/view/index'),
			meta: {
				topTransparent: true,
				keepAlive: true
			}
		},
		{
			path: '/porsche',
			component: () => import('@/view/porsche'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/usedCarCenter',
			name: '二手车中心',
			component: () => import('@/view/usedCarCenter'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/serviceCenter',
			name: '客户互动服务中心',
			component: () => import('@/view/serviceCenter'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/brand/:id',
			name: 'brand',
			component: () => import('@/view/brand'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: () => import('@/view/privacy'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/legalNotice',
			name: 'legalNotice',
			component: () => import('@/view/legalNotice'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/cookie',
			name: 'cookie',
			component: () => import('@/view/cookie'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/search',
			name: 'search',
			component: () => import('@/view/search'),
			meta: {
				keepAlive: false
			}
		},
		{
			path: '/csr',
			name: 'csr',
			component: () => import('@/view/csr'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/compliance',
			name: 'compliance',
			component: () => import('@/view/compliance'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/Environment',
			name: 'Environment',
			component: () => import('@/view/Environment'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/sustainability',
			name: 'sustainability',
			component: () => import('@/view/sustainability/index'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/sustainability/info',
			name: 'sustainabilityInfo',
			component: () => import('@/view/sustainability/info'),
			meta: {
				keepAlive: false
			}
		},
		{
			path: '/404',
			name: '404',
			component: () => import('@/view/404'),
			meta: {
				keepAlive: true
			}
		},
		...brandStory,
		...activity,
		...aboutUs,
		{
			path: '*', // 页面不存在的情况下会跳到404页面
			redirect: '/404',
			name: 'notFound',
			hidden: true
		}

	]
});
router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	console.log(1)
})

export default router;
