<template>
	<div id="app">
		<service></service>
		<top id="top"></top>
		<div class="routerView" v-if="!$route.meta.keepAlive">
			<router-view :key="$route.fullPath" />
		</div>
		<div class="routerView" v-else>
			<keep-alive>
				<router-view />
			</keep-alive>
		</div>
		<bottom></bottom>
		<a href="#top"><img src="./assets/top.png" class="hadb" /></a>
	</div>
</template>

<script>
	import mixinLang from './components/mixin.js'
	import top from './components/top.vue'
	import bottom from './components/bottom.vue'
	import service from './components/service.vue'

	export default {
		name: 'app',
		mixins: [mixinLang],
		components: {
			top,
			bottom,
			service
		},
		data() {
			return {
				topTransparent: ''
			}
		},
		watch: {
			$route(to, from) {
				this.topTransparent = to.meta.topTransparent ? to.meta.topTransparent : false
			},
		},
		methods: {
			toTop() {
				document.body.scrollTop = 0;
			},
			notPc() {
				if ((navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					))) {
					if (window.location.hash == '#/news') {
						window.location.href = 'https://www.paig.com.cn/mobile/#/newsList'
					} else {
						window.location.href = 'https://www.paig.com.cn/mobile/' + window.location.hash
					}
				}
			}
		},
		mounted() {
			this.notPc()
			window.addEventListener('resize', this.notPc());
		}
	}
</script>

<style lang="scss">
	@import url("https://www.paig.com.cn/web/index.css");
	//@import url("https://eventplus.viewcenter.cn/web/index.css");

	@font-face {
		font-family: SourceHanSansCN-Bold;
		src: url('./assets/font/SourceHanSansCN-Bold.ttf');
	}

	@font-face {
		font-family: SourceHanSansCN-Heavy;
		src: url('./assets/font/SourceHanSansCN-Heavy.ttf');
	}

	@font-face {
		font-family: SourceHanSansCN-Light;
		src: url('./assets/font/SourceHanSansCN-Light.ttf');
	}

	@font-face {
		font-family: SourceHanSansCN-Medium;
		src: url('./assets/font/SourceHanSansCN-Medium.ttf'),
	}

	@font-face {
		font-family: SourceHanSansCN-Normal;
		src: url('./assets/font/SourceHanSansCN-Normal.ttf');
	}

	@font-face {
		font-family: SourceHanSansCN-Regular;
		src: url('./assets/font/SourceHanSansCN-Regular.ttf');
	}

	* {
		margin: 0;
		padding: 0;
	}

	body {
		font-size: 22px !important;
		font-weight: 350;
		font-family: SourceHanSansCN-Medium, sans-serif;
		line-height: 40px;
		//  !important
	}

	html {
		overflow-x: hidden;
		font-family: SourceHanSansCN-Medium, sans-serif !important;
		//  
		font-size: 22px;
	}

	div {
		box-sizing: border-box;
	}

	img {
		object-fit: cover;
	}

	#app {
		min-height: 100vh;
		position: relative;
	}

	/* 整个滚动条 */
	::-webkit-scrollbar {
		/* 对应纵向滚动条的宽度 */
		width: 10px;
		/* 对应横向滚动条的宽度 */
		height: 10px;
	}

	/* 滚动条上的滚动滑块 */
	::-webkit-scrollbar-thumb {
		background-color: rgba(203, 203, 203, .5);
		border-radius: 32px;
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		background-color: rgba(255, 255, 255, .5);
		border-radius: 32px;
	}

	.hadb {
		width: 70px;
		position: fixed;
		bottom: 10px;
		right: 10px;
		z-index: 99;
	}

	/deep/ .swiper-container {
		transform: translate3d(0, 0, 0);
	}

	/deep/.swiper-slide {
		transform: translate3d(0, 0, 0);
	}

	.titTop {
		// 
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 35px;
		font-weight: bold;
		color: #B5A191;
		margin-bottom: 15px;
		position: relative;
		text-transform: uppercase;
		margin-top: 20px;
		line-height: normal;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 129.59px;
			height: 4px;
			background: #97233F;
			top: -10px;
			left: 0;
		}
	}

	.routerView {
		min-height: calc(100vh - 480px);
		background-color: #202938;
	}

	.page {
		padding-top: 100px;
		background-color: #040E0D;
		min-height: calc(100vh - 480px);
	}

	.titBottom {
		// 
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #31302E;
		color: #000;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.titText {
		line-height: 40px !important;
	}

	.yellowLine {
		width: 134px;
		height: 4px;
		background-color: #97233F;
		position: relative;
		margin-top: 49px;

		&::after {
			content: '';
			display: block;
			height: 4px;
			width: 35px;
			background-color: #B5A191;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
</style>