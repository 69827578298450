<template>
	<div class="service">
		<div class="content" v-if="listShow">
			<div class="title">
				<div>
					<!-- <img src="../assets/serviceLogo.png" /> -->
					<span>{{lang==6?'联系我们':'Contact Us'}}</span>
				</div>
				<img src="../assets/close.png" @click="listShow=false" />
			</div>
			<div class="list">
				<div class="tit">{{lang==6?'请选择您需要的服务':" Please select your intended service"}}</div>
				<div class="con">
					<div v-for="(item,index) in brandList" :key="index" @click="popshow=true;cur=item.id">
						{{lang==6?item.title:item.titleEN}}
					</div>
					<!-- <div @click="popshow=true;cur={title: 'Car'}">{{lang==6?'二手车':'Used Car'}}</div>
					<div @click="popshow=true;cur='Group'">{{lang==6?'集团大客户':'Group Fleet Customer'}}</div> -->
				</div>
			</div>
			<div class="pop" v-if="popshow">
				<div>
					<div class="popcon">
						<img :src="require('../assets/top/code/code.jpg')" />
						<div v-if="lang==6">
							<p>为更好地为你提供服务</p>
							<p>请您添加我们的企业微信进行进一步沟通</p>
						</div>
						<div v-else>
							To provide better service to you, please add our Wecom for further communication.
						</div>
					</div>
					<img src="../assets/close1.png" class="close" @click="popshow=false" />
				</div>
			</div>
		</div>
		<img :src="require('../assets/top/icon'+(lang==6?'':'En')+'.png')" class="serviceIcon" @click="listShow=true" />
	</div>
</template>

<script>
	import mixinLang from './mixin.js'
	export default {
		name: 'service',
		mixins: [mixinLang],
		data() {
			return {
				brandList: [{
						title: '奥迪',
						titleEN: 'Audi',
						id: 1
					},
					{
						title: '宾利',
						titleEN: 'Bentley',
						id: 2
					}, {
						title: '兰博基尼',
						titleEN: 'Lamborghini',
						id: 3
					}, {
						title: '保时捷',
						titleEN: 'Porsche',
						id: 4
					}, {
						title: '宝马',
						titleEN: 'BMW',
						id: 5
					}, {
						title: 'MINI',
						titleEN: 'MINI',
						id: 6
					},
					// {
					// 	title: '二手车',
					// 	titleEN: 'Used Car',
					// 	id: 7
					// },
					{
						title: '集团大客户',
						titleEN: 'Group Fleet Customer',
						id: 8
					},
					// {
					// 	title: 'Join us',
					// 	titleEN: 'Join us',
					// 	id: 9
					// }
				],
				popshow: false,
				listShow: false,
				cur: ''
			}
		},
		methods: {
			// getInfo() {
			// 	// 品牌列表
			// 	this.$api.getBrandList({
			// 		cate_no: 'b4766587',
			// 	}, {
			// 		page: 1,
			// 		limit: 100
			// 	}).then(res => {
			// 		this.brandList = res.list
			// 	})
			// }
		}
	}
</script>

<style lang="scss" scoped>
	.service {
		position: fixed;
		right: 10px;
		bottom: 100px;
		z-index: 99;
		text-align: right;
	}

	.serviceIcon {
		width: 70px;
		height: 70px;
		border-radius: 5px;
		background-color: #97233F;
		margin-top: 30px;
		cursor: pointer;
	}

	.content {
		position: relative;
		width: 360px;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
		background-color: #FFF;
		border-radius: 8px;

		.title {
			border-radius: 8px 8px 0px 0px;
			background: #97233F;
			height: 14.4%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 20px;
			color: #FFF;
			font-family: SourceHanSansCN-Regular, sans-serif;
			font-size: 14px;
			text-transform: uppercase;

			img {
				width: 22px;
				height: 22px;
			}

			>div {
				img {
					width: 56px;
					height: 56px;
					border-radius: 50px;
					border: 1px solid rgba(255, 255, 255, 0.3);
					vertical-align: middle;
					margin-right: 20px;
				}
			}
		}

		.list {
			text-align: center;
			padding: 23px 33px 0;
			font-family: SourceHanSansCN-Regular, sans-serif;
			color: #97233F;

			.tit {
				font-size: 20px;
				margin-bottom: 30px;
			}

			.con {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				>div {
					width: 48%;
					border-radius: 8px;
					box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
					height: 60px;
					line-height: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					margin-bottom: 30px;
					cursor: pointer;
				}
			}
		}

		.pop {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-family: SourceHanSansCN-Regular, sans-serif;
			text-align: center;

			.close {
				width: 44px;
				margin: 20px auto 0;
				display: block;
				cursor: pointer;
			}

			.popcon {
				display: inline-block;
				width: auto;
				padding: 32px 10px;
				background-color: #FFF;
				border-radius: 8px;
				text-align: center;

				img {
					width: 175px;
				}
			}

		}
	}
</style>