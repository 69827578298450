const mixinLang = {
	data() {
		return {
		};
	},
	computed: {
		lang() {
			return this.$store.state.lang;
		}
	},
	created() {
		this.getInfo()
	},
	watch: {
		lang: function(a, b) {
			this.getInfo()
		}
	},
	methods:{
		getInfo(){}
	}
};

export default mixinLang;
