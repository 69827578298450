// 品牌故事
export default [{
	path: '/brandStory/introduce',
	name: 'brandStoryIntroduce',
	component: () => import('@/view/brandStory/introduce'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/news',
	name: 'brandStoryNewsIndex',
	component: () => import('@/view/brandStory/news/index'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/newsList',
	name: 'BrandStoryNewsList',
	component: () => import('@/view/brandStory/news/list'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/brandStory/news/details/:id',
	name: 'brandStoryNewsDetails',
	component: () => import('@/view/brandStory/news/details'),
	meta: {
		keepAlive: false
	}
}, {
	path: '/history',
	name: 'brandStoryHistory',
	component: () => import('@/view/brandStory/history'),
	meta: {
		keepAlive: true
	}
}];
