// 集团活动
export default [{
	path: '/activityBrand',
	name: 'activityBrand',
	component: () => import('@/view/activity/brand'),
	meta: {
		keepAlive: true
	}
}, {
	path: '/activity/details/:id',
	name: 'activityDetails',
	component: () => import('@/view/activity/details'),
	meta: {
		keepAlive: false
	}
}, {
	path: '/activityGroup',
	name: 'activityGroup',
	component: () => import('@/view/activity/group'),
	meta: {
		keepAlive: true
	}
}];
